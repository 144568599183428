import React from 'react';

import './index.css';

function Footer() {
  return (
    <div className='Footer'>
      Design: Kamil Mastalerz
    </div>
  );
}

export default Footer